/*
 * ******************************************************
 *  * Copyright (C) 2018-2020 webcrab GmbH
 *  * This file is part of ista VDM 2.0.
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by <phillip@webcrab.at>
 *  ******************************************************
 */

import {Model, useRepo} from 'pinia-orm';
import EnergyCarrier from './EnergyCarrier';
import GridOperator from './GridOperator';
import User from './User';
import EnergyCommunity from './EnergyCommunity';
import EnergyDirection from '../enums/EnergyDirection';
import Flat from "@/models/Flat.js";
import Property from "@/models/Property.js";

export default class Meter extends Model {
  static entity = 'meters';

  static fields() {
    return {
        id: Meter.attr(''),
        uuid: Meter.attr(''),
        description: Meter.string(null),
        serialnumber: Meter.string(null),
        meter_point_number: Meter.string(null),
        device_type_smart: Meter.string(null),
        created_by_user_id: Meter.attr(null),
        created_by: Meter.belongsTo(User, 'created_by_user_id'),
        consumption: Meter.number(0),
        location: Meter.string(''),
        area: Meter.number(0),
        eda_connection_state: Meter.attr(null),
        eda_connection_state_title: Meter.attr(null),
        energyCarrier_id: Meter.attr(null),
        energyCarrier: Meter.belongsTo(EnergyCarrier, 'energyCarrier_id'),
        gridOperator_id: Meter.attr(0),
        gridOperator: Meter.belongsTo(GridOperator, 'gridOperator_id'),
        energyCommunity_id: Meter.attr(0),
        energyCommunity: Meter.belongsTo(EnergyCommunity, 'energyCommunity_id'),
        energy_direction: Meter.number(null),
        meters_id: Meter.attr(null),
        meters_type: Meter.attr(null),
        meters: Meter.morphTo([Flat, Property], 'meters_id', 'meters_type'),
        authorize: Meter.attr([]),
        meta: Meter.attr(null),
        precision: Meter.attr(null),
        settings: Meter.attr({}),
        data_received_at: Meter.attr(null),
    };
  }

  get energyDirection() {
    return this.energy_direction === EnergyDirection.CONSUMPTION
      ? 'Verbrauch'
      : 'Produktion';
  }

    static repo() {
        return useRepo(Meter);
    }
}
