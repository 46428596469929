import User from '@/models/User.js';
import DataAccessModal from '@/components/Tenant/DataAccessModal.vue';

export function useDataAccessGuard(router) {
    return async (to, from, next) => {
        if (!to.name?.startsWith('tenant') || User.me().hasDataAccessEnabled) {
            next();
            return;
        }

        router.app.$modal.show(
            DataAccessModal,
            {},
            {
                adaptive: true,
                focusTrapped: true,
                clickToClose: false,
                height: 'auto',
            },
            {
                'before-close': () => {
                    if (User.me().hasDataAccessEnabled) {
                        next(to.path);
                    } else {
                        User.logout();
                        router.app.$modal.show('dialog', {
                            title: 'Einwilligung nicht erteilt',
                            text:
                                'Ohne Ihre Zustimmung sind wir nicht berechtigt Ihre Daten zu verarbeiten und ein Login ins VDM-Portal kann nicht erfolgen. ' +
                                'Sie haben die Möglichkeit der Verarbeitung Ihrer Daten beim nächsten Login zuzustimmen. ' +
                                'Verwenden Sie dafür die von Ihnen gewählte E-Mailadresse sowie das von Ihnen gewählte Passwort.',
                            class: 'has-text-body',
                            buttons: [
                                {
                                    title: 'Verstanden',
                                    class: 'btn btn-success',
                                    handler: () => {
                                        router.go(0);
                                    },
                                },
                            ],
                        });
                    }
                },
            },
        );
        if (from.name === null) {
            next('/');
        } else {
            next(false);
        }
    };
}

export default useDataAccessGuard;
